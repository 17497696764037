import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["notification"];

  connect() {
    setTimeout(() => {
      this.destroy();
    }, 10000);
  }

  destroy() {
    if (this.hasNotificationTarget) {
      this.notificationTarget.remove();
    }
  }
}
